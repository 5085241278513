exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-css-js": () => import("./../../../src/pages/about-css.js" /* webpackChunkName: "component---src-pages-about-css-js" */),
  "component---src-pages-barge-services-js": () => import("./../../../src/pages/barge-services.js" /* webpackChunkName: "component---src-pages-barge-services-js" */),
  "component---src-pages-caretaking-js": () => import("./../../../src/pages/caretaking.js" /* webpackChunkName: "component---src-pages-caretaking-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-css-js": () => import("./../../../src/pages/team-css.js" /* webpackChunkName: "component---src-pages-team-css-js" */),
  "component---src-pages-work-with-css-js": () => import("./../../../src/pages/work-with-css.js" /* webpackChunkName: "component---src-pages-work-with-css-js" */),
  "component---src-templates-job-post-js": () => import("./../../../src/templates/job-post.js" /* webpackChunkName: "component---src-templates-job-post-js" */),
  "component---src-templates-project-kind-archive-js": () => import("./../../../src/templates/project-kind-archive.js" /* webpackChunkName: "component---src-templates-project-kind-archive-js" */),
  "component---src-templates-project-kind-page-js": () => import("./../../../src/templates/project-kind-page.js" /* webpackChunkName: "component---src-templates-project-kind-page-js" */),
  "component---src-templates-project-post-archive-js": () => import("./../../../src/templates/project-post-archive.js" /* webpackChunkName: "component---src-templates-project-post-archive-js" */),
  "component---src-templates-project-post-js": () => import("./../../../src/templates/project-post.js" /* webpackChunkName: "component---src-templates-project-post-js" */),
  "component---src-templates-review-post-archive-js": () => import("./../../../src/templates/review-post-archive.js" /* webpackChunkName: "component---src-templates-review-post-archive-js" */)
}

